import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import PostMeta from "./Post/PostMeta";

const StyledItem = styled(props => <Link {...props} />)`
	display: block;
	width: 100%;
	color: #000000;
	text-decoration: none;
	border: 1px solid transparent;
	border-radius: 10px;
	margin: calc(20px * 2) 0 calc(20px * 3);
	padding: 10px;
	position: relative;
	transition: all 0.5s;
	background: transparent;

	.gatsby-image-outer-wrapper {
		display: block;
		border-radius: 10px;
		border: 1px solid #ecebea;
		overflow: hidden;
	}

	&:after {
		border-top: 1px solid #ecebea;
		content: "";
		height: 0;
		position: absolute;
		bottom: calc(20px * -1.5);
		left: 50%;
		transform: translateX(-50%);
		transition: all 0.5s;
		width: 50%;
	}

	.post__h1 {
		line-height: 1.1;
		font-size: 2em;
		font-weight: bold;
	}

	.post__content {
		display: inline-block;
		padding: 20px 10px 0;
	}

	.post__p {
		line-height: 1.5;
	}

	@media (min-width: 600px) {
		margin: calc(20px * 3) 0 calc(20px * 4);
		padding: 20px;

		&:after {
			bottom: calc(20px * -2);
		}

		&:first-child {
			&:before {
				top: calc(20px * -1.75);
			}
		}

		.post__h1 {
			font-size: calc(2em * 1.2);
			transition: all 0.5s;
		}

		.post__content {
			padding: calc(20px * 1.5) 20px 0;
		}
	}
	@media (min-width: 1024px) {
		margin: calc(20px * 4) 0 calc(20px * 5);
		padding: 0 0 calc(20px * 2);

		&:after {
			bottom: calc(20px * -1.5);
		}

		&:first-child {
			&:before {
				top: calc(20px * -2.75);
			}
		}

		.post__h1 {
			font-size: 2.5em;
		}

		.post__content {
			padding: calc(20px * 1.2) calc(20px * 2) 0;
		}

		&:hover {
			border: 1px solid #ecebea;
			box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.03);

			&:after {
				bottom: calc(20px * -2.5);
			}
			.gatsby-image-wrapper {
				transform: scale(1.1);
			}
			.post__h1 {
				color: #2aad7c;
			}
		}
		.gatsby-image-wrapper {
			transition: all 0.5s;
		}
	}
`;

const Item = ({ post, category }) => {
	const {
		frontmatter: {
			title,
			description,
			author,
			lang,
			url,
			date,
			cover: {
				childImageSharp: { fluid }
			}
		}
	} = post;
	return (
		<StyledItem to={`/${lang}/blog/${url}`} key={url} className="link">
			<span className="gatsby-image-outer-wrapper">
				<Img fluid={fluid} />
			</span>
			<span className="post__content">
				<h1 className="post__h1">{title}</h1>
				<PostMeta date={date} author={author} category={category} />
				<p className="post__p">{description}</p>
			</span>
		</StyledItem>
	);
};

Item.propTypes = {
	post: PropTypes.shape({
		frontmatter: PropTypes.shape({
			title: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
			author: PropTypes.string.isRequired,
			lang: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
			date: PropTypes.string.isRequired,
			cover: PropTypes.object.isRequired
		})
	}).isRequired,
	category: PropTypes.shape({
		title: PropTypes.string.isRequired
	}).isRequired
};

export default Item;
