import React from "react";
import styled from "styled-components";

const StyledCallToAction = styled.div`
	display: inline-block;
	line-height: normal;
	@media only screen and (max-width: 1200px) {
		display: block;
		min-width: 220px;
		width: 100%;
		margin: 30px auto 0 auto;
	}
`;

const CallToAction = ({ ...rest }) => <StyledCallToAction {...rest} />;

export default CallToAction;
