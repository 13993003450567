import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "styled-components";
import SVGIcon from "../../SVGIcon";

const StyledPostMeta = styled.p`
	display: flex;
	flex-flow: row wrap;
	font-size: 0.8em;
	margin: 20px 0;
	background: transparent;

	svg {
		fill: #2aad7c;
		margin: 0 2px 0 0;
	}
	span {
		align-items: center;
		display: flex;
		text-transform: uppercase;
		margin: 5px 10px 5px 0;
	}

	@media (min-width: 600px) {
		margin: ${`calc(20px * 1.5) 0 20px`};
	}
`;

const PostMeta = ({ date, author, category, lang }) => {
	const formatedDate = new Date(date);
	const hasCategoryLink = (cat, lan) => {
		return (
			cat.url === "" ||
			typeof cat.url === "undefined" ||
			lan === "" ||
			typeof lan === "undefined"
		);
	};
	return (
		<StyledPostMeta>
			<span>
				<SVGIcon
					name="faCalendar"
					className="green"
					style={{ width: "18px", height: "18px" }}
				/>
				{`${formatedDate.getDate()}. `}
				{`${formatedDate.getMonth() + 1}. `}
				{`${formatedDate.getFullYear()}`}
			</span>
			<span>
				<SVGIcon
					name="faUser"
					className="green"
					style={{ width: "18px", height: "18px" }}
				/>
				{author}
			</span>
			<span>
				<SVGIcon
					name="faTag"
					className="green"
					style={{ width: "18px", height: "18px" }}
				/>
				{hasCategoryLink(category, lang) ? (
					category.title
				) : (
					<Link to={`/${lang}/blog/${category.url}`}>{category.title}</Link>
				)}
			</span>
		</StyledPostMeta>
	);
};

PostMeta.propTypes = {
	date: PropTypes.string.isRequired,
	author: PropTypes.string.isRequired,
	category: PropTypes.shape({
		url: PropTypes.string,
		title: PropTypes.string.isRequired
	}),
	lang: PropTypes.string
};

PostMeta.defaultProps = {
	lang: "",
	category: {
		url: ""
	}
};

export default PostMeta;
