import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Item from "./Item";

const StyledMain = styled(props => <div {...props} />)`
	margin: -60px auto 0 auto;
	position: relative;
	.main {
		padding: 0 20px;
		list-style: none;
		padding: 30px 0 10px;
	}

	@media (min-width: 600px) {
		max-width: 650px;
		.main {
			padding: 0 0 30px;
		}
	}

	@media (min-width: 1024px) {
		max-width: 700px;
		.main {
		}
	}
`;

const BlogItem = ({ posts, categories }) => {
	let formatedCategories = categories.map(category => {
		const {
			node: {
				frontmatter: { title, categoryName }
			}
		} = category;
		const result = {};
		result[categoryName] = { title };
		return result;
	});
	formatedCategories = Object.assign({}, ...formatedCategories);
	if (posts.length > 0) {
		return (
			<StyledMain>
				<ul className="main">
					{posts.map(post => {
						const {
							node,
							node: {
								frontmatter: { url, category }
							}
						} = post;
						return (
							<li key={`${url}-li`}>
								<Item
									key={url}
									post={node}
									category={formatedCategories[category]}
								/>
							</li>
						);
					})}
				</ul>
			</StyledMain>
		);
	}
	return null;
};

BlogItem.propTypes = {
	posts: PropTypes.arrayOf(
		PropTypes.shape({
			totalCount: PropTypes.number,
			frontmatter: PropTypes.shape({
				title: PropTypes.string.isRequired,
				category: PropTypes.string.isRequired,
				url: PropTypes.string.isRequired,
				date: PropTypes.string.isRequired,
				author: PropTypes.string.isRequired,
				lang: PropTypes.string.isRequired,
				cover: PropTypes.string.isRequired
			})
		})
	),
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			edges: PropTypes.shape({
				node: PropTypes.shape({
					frontmatter: PropTypes.shape({
						categoryName: PropTypes.string.isRequired,
						title: PropTypes.string.isRequired
					})
				})
			})
		}).isRequired
	).isRequired
};

BlogItem.defaultProps = {
	posts: {
		totalCount: null
	}
};

export default BlogItem;
