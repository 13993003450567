import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ButtonLink from "./ButtonLink";
import ContentRow from "./ContentRow";
import SVGIcon from "./SVGIcon";

const ContactRowContent = styled.div`
	height: 80px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	color: #ffffff;
	width: 100%;
	padding: 10px 0;

	.contact-icon {
		display: inline-block;
		font-weight: lighter;
		width: 60px;
		height: 40px;
		line-height: 40px;
		vertical-align: middle;
		padding-right: 20px;
	}

	.contact-message {
		display: flex;
		align-items: center;
		padding: 10px 0;
	}

	@media only screen and (min-width: 2350px) {
		font-size: 22px;
	}

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		line-height: 20px;
		height: auto;
		box-sizing: border-box;
		padding: 30px;
	}
`;

const ContactRow = ({ pageContext }) => {
	return (
		<ContentRow
			rowContentClass="no-padding"
			style={{ background: "#2aad7c", color: "#fff" }}
		>
			<ContactRowContent className="contact-row-content">
				<div className="contact-message">
					<div className="contact-icon">
						<SVGIcon
							name="faUserCircle"
							className="white"
							style={{
								width: "100%",
								height: "100%"
							}}
						/>
					</div>
					{pageContext.CONTACT_US_CONTENT}
				</div>
				<div className="contact-button">
					<ButtonLink
						link={`/${pageContext.lang}/${pageContext.MENU.CAREER.URI}`}
						label={pageContext.CONTACT_US_BUTTON}
						classString="link white-font"
						alt={pageContext.MENU.CAREER.NAV}
					/>
				</div>
			</ContactRowContent>
		</ContentRow>
	);
};

ContactRow.propTypes = {
	pageContext: PropTypes.object.isRequired
};

export default ContactRow;
