import React from "react";
import BackgroundImage from "gatsby-background-image";
import PropTypes from "prop-types";

const BGImg = ({ hookData, children, ...rest }) => {
	if (
		typeof hookData.childImageSharp !== "undefined" &&
		hookData.childImageSharp.fluid
	) {
		return (
			<BackgroundImage
				fluid={hookData.childImageSharp.fluid}
				{...rest}
				fadeIn={false}
			>
				{children}
			</BackgroundImage>
		);
	}
	return null;
};

BGImg.propTypes = {
	children: PropTypes.node,
	hookData: PropTypes.shape({
		childImageSharp: PropTypes.shape({
			fluid: PropTypes.shape({
				aspectRatio: PropTypes.number,
				sizes: PropTypes.string,
				src: PropTypes.string,
				srcSet: PropTypes.string,
				srcSetWebp: PropTypes.string,
				srcWebp: PropTypes.string
			}).isRequired
		}).isRequired
	}).isRequired
};

BGImg.defaultProps = {
	children: null
};

export default BGImg;
