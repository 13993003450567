import styled from "styled-components";

const StyledCommonContent = styled.div`
	display: flex;
	align-items: stretch;

	&.center {
		justify-content: center;
	}

	.common-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		width: 100%;
	}

	.content {
		font-weight: 400;
		font-size: 14px;
		font-weight: 500;
	}
	p {
		margin-bottom: 10px;
	}
	.big {
		font-size: 14px;
		font-weight: 500;
	}
	.headline {
		font-family: Montserrat;
		margin-bottom: 20px;
	}
	h3 {
		margin-bottom: 30px;
		color: #2aad7c;
		font-family: Montserrat;
		font-size: 36px;
		font-weight: 400;
	}
`;
export default StyledCommonContent;
