import React from "react";
import PropTypes from "prop-types";
import StyledCommonContent from "./style";
import CallToAction from "../CallToAction";

const CommonContent = ({
	mainClass,
	buttons,
	headline,
	contentSize,
	content,
	wrapperClass,
	aligned
}) => {
	let customHeadline = <h2>{headline}</h2>;
	if (contentSize === "big") {
		customHeadline = <h1>{headline}</h1>;
	}

	let customContent;
	if (Array.isArray(content)) {
		customContent = content.map(row => {
			return <p key={row} dangerouslySetInnerHTML={{ __html: row }} />;
		});
	} else if (content !== "") {
		customContent = <p dangerouslySetInnerHTML={{ __html: content }} />;
	}

	const vertical = {};
	vertical.bottom = (
		<StyledCommonContent className={wrapperClass}>
			<div className={mainClass}>
				<div className="headline">{customHeadline}</div>
				<div className="content">
					{customContent}
					{buttons.map(button => {
						return (
							<CallToAction key={`${button.props.link}`}>{button}</CallToAction>
						);
					})}
				</div>
			</div>
		</StyledCommonContent>
	);
	vertical.top = (
		<StyledCommonContent className={wrapperClass}>
			<div className={mainClass}>
				<div className="content">
					<div className="headline">{customHeadline}</div>
					{customContent}
				</div>
				{buttons.map(button => {
					return (
						<CallToAction key={`${button.props.link}`}>{button}</CallToAction>
					);
				})}
			</div>
		</StyledCommonContent>
	);

	return vertical[aligned];
};

CommonContent.defaultProps = {
	mainClass: "common-content",
	buttons: [],
	aligned: "bottom"
};

CommonContent.propTypes = {
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	buttons: PropTypes.arrayOf(PropTypes.element)
};

export default CommonContent;
