import styled from "styled-components";

const StyledContentHeader = styled.div`
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
	width: 100%;
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: auto;
	.header-content {
		align-self: center;
	}

	.header-content {
		position: relative;
		left: 100px;
		width: 600px;
		padding: 80px 0 40px 0;
	}
	.header-content .headline {
		color: #ffffff;
		font-size: 26px;
		font-weight: 400;
	}
	.header-content .content {
		color: #dadada;
		line-height: 30px;
	}
	.header-content .content p {
		font-size: 16px;
	}
	@media only screen and (max-width: 1200px) {
		.header-content {
			position: relative;
			width: 100%;
			left: 0;
			padding: 50px 20px 20px 20px;
			box-sizing: border-box;
		}
		.header-content .headline {
			color: #ffffff;
		}
		.header-content .headline h1 {
			font-size: 26px;
		}
		.header-content .content {
			color: #b0b0b0;
			font-size: 14px;
		}
	}
	@media only screen and (min-width: 2350px) {
		.header-content {
			position: relative;
			left: 120px;
			width: 800px;
		}
		.header-content .headline h1 {
			font-size: 72px;
		}
		.header-content .content {
			line-height: 42px;
		}
		.header-content .content p {
			font-size: 28px;
			line-height: 42px;
		}
	}
`;

export default StyledContentHeader;
